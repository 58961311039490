import React from 'react'
import { Link } from 'gatsby'
import icon5 from '../../assets/images/icons/icon5.png'

const DocumentCard = ({ media }) => {
    const { name, original_url } = media

    return (
        <Link to={original_url} target='_blank'>
            <p><img src={icon5} alt='banner' style={{ width: 20 }} /> {name}</p>
        </Link>
    )
}

export default DocumentCard