import React from 'react'
import { Link } from 'gatsby'
import course1 from '../../assets/images/courses/course1.jpg'
import { format } from 'date-fns'

const ServiceCard = ({ service }) => {
    const { media, name, price, slug, start_date, summary } = service

    const formatter = new Intl.NumberFormat('es-ES', {
        style: 'currency',
        currency: 'EUR',
    })

    return (
        <div className='col-12 col-md-4'>
            <div className='single-services-box h-100'>
                <div className='services-image'>
                    <Link to={`/service-details?slug=${slug}`} className='d-block image'>
                        <img src={media[0] ? media[0].original_url : course1} alt='about' />
                    </Link>
                    <Link to={`/service-details?slug=${slug}`} className='fav'>
                        <i className='flaticon-heart'></i>
                    </Link>
                    <div className='price shadow'>{formatter.format(price)}</div>
                </div>
                <div className='services-content'>
                    <h3>
                        <Link to={`/service-details?slug=${slug}`}>
                            {name}
                        </Link>
                    </h3>
                    <p>{summary}</p>
                    {/* <ul className='services-box-footer d-flex justify-content-between align-items-center'>
                        <li>
                            <i className='flaticon-agendas'></i> {  format(new Date(start_date), 'dd/MM/yyyy HH:mm')}
                        </li>
                    </ul> */}
                </div>
            </div>
        </div>
    )
}

export default ServiceCard