import React from 'react'
import ServiceCard from './ServiceCard'

const RelatedServices = ({ services }) => {

    return (
        <div className='services-area bg-fafafb pt-100 pb-70'>
            <div className='container'>
                <div className='section-title'>
                    <h2>Otros Servicios</h2>
                </div>
                <div className='row'>
                    {services.map((service) => {
                        return (
                            <ServiceCard
                                key={`related-service-${service.id}`}
                                service={service}
                            />
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default RelatedServices