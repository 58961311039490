import React, { useEffect, useState } from 'react'
import Layout from '../components/App/Layout'
import PageBanner from '../components/Common/PageBanner'
import ServiceDetailsContent from '../components/ServiceDetails/ServiceDetailsContent'
import { getService } from '../api/service'
import { navigate } from 'gatsby'

const ServiceDetails = ({ location }) => {
    const [documents, setDocuments] = useState(null)
    const [service, setService] = useState(null)
    const [services, setServices] = useState(null)

    const params = new URLSearchParams(location.search)
    const slug = params.get('slug')

    useEffect(() => {
        (async () => {
            try {
                setDocuments(null)
                setService(null)
                setServices(null)
                const data = await getService(slug)
                setDocuments(data?.service.documents)
                setService(data?.service)
                setServices(data?.services)
            } catch (error) {
                console.log(error);
                setDocuments(null)
                setService(null)
                setServices(null)
                navigate('/404')
            }
        })()
    }, [slug])

    if (!service) return null

    return (
        <Layout title={service.name}>
            <PageBanner
                pageTitle={service.name}
                homePageText='Servicios'
                homePageUrl='/services'
                activePageText={service.name}
            />
            <ServiceDetailsContent
                documents={documents}
                service={service}
                services={services}
            />
        </Layout>
    )
}

export default ServiceDetails